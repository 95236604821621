import React from 'react';

import './style.scss';
import WebFont from 'webfontloader';

import Helmet from './helmet'
import Navbar from './navbar'
import Footer from './footer'

class Layout extends React.Component {

	componentDidMount = () => {
		WebFont.load({
			typekit: {
				id: 'vtg3rqh'
			},
			google: {
				families: [`Roboto\:300,400,500,700`,]
			},
		});


	}
	render() {
		return (
			<div id="layout" className={this.props.hasScroll ? 'scroll-parent' : ''}>
				< Helmet />
				<Navbar
					showQuote={typeof this.props.showQuote !== 'undefined' ? this.props.showQuote : true}
					location={this.props.location}
				/>
				{ this.props.children}
				{ !this.props.hideFooter && <Footer type={this.props.formType ? this.props.formType : ''} />}
			</div >
		)
	}
}

export default Layout;
