import React, { useState } from 'react';
import styled from '@emotion/styled'
import { Link } from 'gatsby';

import { Location } from '@reach/router'
import { slide as Menu } from 'react-burger-menu'
import offCanvasClose from '../assets/off-canvas-close.svg'
import logo from '../assets/logo.svg'
import phone from '../assets/free-quote-icon.svg'
import burgerIcon from '../assets/burger-icon.svg'

import offHome from '../assets/nav/home.svg'
import offOptions from '../assets/nav/options.svg'
import offQuality from '../assets/nav/quality.svg'

import offDesign from '../assets/nav/design.svg'
import offProducts from '../assets/nav/products.svg'
import offReviews from '../assets/nav/reviews.svg'
import offAbout from '../assets/nav/about.svg'
import offContact from '../assets/nav/contact.svg'
import offCareers from '../assets/nav/careers.svg'
import offStore from '../assets/nav/store.svg'

import * as Scroll from 'react-scroll';

const Navbar = ({ showQuote, location = null }) => {
	const homeItems = [
		{
			url: 'home',
			label: 'Home',
		},
		{
			url: 'options',
			label: 'Options',
			icon: offOptions
		},
		{
			url: 'quality',
			label: 'Quality',
		},
		{
			url: 'equipment',
			label: 'Equipment',
		},
		{
			url: 'reviews',
			label: 'Reviews',
		},
		{
			url: 'contact',
			label: 'Contact Us',
		},
	]

	// Used for Offcanvas
	const items = [
		{
			url: '/',
			label: 'Home',
			icon: offHome
		},
		{
			url: '/#options',
			label: 'Options',
			icon: offOptions
		},
		{
			url: '/#quality',
			label: 'Quality',
			icon: offQuality
		},
		{
			url: '/#equipment',
			label: 'Equipment',
			icon: offProducts
		},
		{
			url: '/#reviews',
			label: 'Reviews',
			icon: offReviews
		},
		{
			url: '#contact',
			label: 'Contact Us',
			icon: offContact
		},
		{
			url: '#',
			label: '|',
			class: 'divider'
		},
		{
			url: '/savings-calculator',
			label: 'Savings Calculator',
			icon: offDesign
		},
		{
			url: '/about-us',
			label: 'About Us',
			icon: offAbout
		},

		{
			url: '/careers',
			label: 'Careers',
			icon: offCareers
		},
	]

	const [menuShown, setMenuShown] = useState(false)

	const toggleMenu = () => {
		setMenuShown(!menuShown)
	}

	const scrollToFn = (id) => {

	}
	const navItemClass = () => 'navbar-item fw-500 fs-12  ls-50 roboto'
	const HomeMenu = () => (

		<Location>
			{({ location }) => {
				if (location.pathname == '/') {
					return (

						<ul className="navbar-start is-hidden-touch">
							{
								homeItems.map(i => (
									<Scroll.Link
										activeClass={'is-active'}
										to={i.url}
										hashSpy={true}
										smooth={'easeInOutQuint'} duration={1000} spy={true}
										className='navbar-item fw-500 fs-12 ls-50 roboto'>{i.label}</Scroll.Link>
								))

							}
							<a className={navItemClass()}>|</a>
							<Link to='/savings-calculator' className={navItemClass()}>
								Savings Calculator
							</Link>
							<Link to='/about-us' className={navItemClass()}>
								About Us
							</Link>
							<Link to='/careers' className={navItemClass()}>
								Careers
							</Link>

						</ul>

					)
				}
				return <p>The location is {location.pathname}</p>
			}}
		</Location>
	)
	return (
		<>


			<StyledNav className="navbar is-fixed-top  is-dark is-transparent" style={{
				zIndex: 9999
			}}>
				<div className="container">

					<div className="navbar-item logo">
						<a href="/">
							<img src={logo} alt="Kota Solar Energy" />
						</a>

						<a className={'free-quote button is-white is-uppercase fw-500 roboto ' +
							(menuShown ? 'offcanvas-open' : '') +
							(!showQuote ? 'hide-quote' : '')
						} href="tel:+18558005682">
							<div>
								<img src={phone} />
								<p className="small fw-100 is-hidden-touch">Free quote</p>
							</div>
							<p className="fs-15 fw-500 roboto ">855 800 5682</p>
						</a>

					</div>

					<Location>
						{({ location }) => {
							if (location.pathname == '/') {
								return HomeMenu()
							}
							else {
								return (
									<div className="navbar-start is-hidden-touch">
										{
											items.map((i, index) => (
												<Link
													to={i.url}
													className={'navbar-item fw-500 fs-12 roboto  ls-50'}
													activeClassName='is-active'
													key={'link-' + index}
												>
													{i.label}
												</Link>
											))
										}
									</div>)
							}
						}}
					</Location>

					<a className="navbar-end  is-hidden-desktop burger" onClick={toggleMenu}>
						{menuShown ? <img src={offCanvasClose} /> : <img src={burgerIcon} />}
					</a>



				</div>
			</StyledNav >
			<Menu
				disableAutoFocus
				customBurgerIcon={false}
				customCrossIcon={false}
				isOpen={menuShown}
				width={'50vw'}
				noOverlay
				right
				menuClassName={'custom-offcanvas'}
			>

				<StyledOffcanvas>
					<div>
						<ul>
							{
								items
									.filter(i => !i.class)
									.map((i, index) => {
										if (location && location.hash != "") {
											if (i.label.toLowerCase() == location.hash.substr(1) ||
												(i.label.toLowerCase() == 'contact us' && location.hash.substr(1) == 'contact')
											) {
												return (
													<li key={'link-' + index}>
														<Link
															to={i.url}
															className={'navbar-item fw-500 fs-12 s-fs-17 ls-50 roboto is-active'}
															onClick={() => toggleMenu()}
														>
															<i><img src={i.icon} /></i>
															{i.label}
														</Link>
													</li>
												)
											}
											return (
												<li key={'link-' + index}>
													<Link
														to={i.url}
														className={'navbar-item fs-12  s-fs-17 ls-50 roboto'}
														onClick={() => toggleMenu()}
													>
														<img src={i.icon} />
														{i.label}
													</Link>
												</li>
											)
										}
										return (
											<li key={'link-' + index}><Link
												to={i.url}
												className={'navbar-item fs-12  s-fs-17 roboto  ls-50'}
												activeClassName='is-active'
												onClick={() => toggleMenu()}
											>
												<img src={i.icon} />
												{i.label}
											</Link></li>
										)
									})
							}
						</ul>
					</div>

				</StyledOffcanvas>
			</Menu >
		</>
	)
}

const StyledNav = styled('nav')`
	& > .container{
		justify-content: space-between;
		align-items: flex-start;
		position: relative;
		@media screen and (min-width: 1200px){
			max-width: 1200px;
			padding-left: 24px;
			padding-right: 24px;
		}
		@media screen and (max-width: 1199px){
			width: 100%;
			padding: 0 2.604166666666667rem;
		}
		@media screen and (max-width: 420px){
			padding: 0;
		}
	}
	&.is-dark{
		background-color: rgba(0,0,0,0);
	}
	width: 100%;
	padding-bottom: 20px;
	background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);

	.logo{
		display: flex;
		margin-top: 20px;
		flex-direction: column;
		border: none;
		display: inline-block;
		& > a > img{
			height: auto;
			max-height: initial;
			width: 116px;
			padding-left: 18px;
			@media screen and (max-width: 1199px){
				width: 13.802083333333333rem;
				padding-left: 2.34375rem;
				margin-top: 0.55rem;
			}

			@media screen and (max-width: 420px){
				width: 25.60386473rem;
				padding: 0;
				margin-left: 4.830917874rem;
			}
			
		}
		
		.free-quote{
			background: #222;
			border: none;
			display: flex;
			flex-direction: column;
			color: #fff;
			padding: 10px 18px;
			margin-top: 10px;
			position: fixed;
			.small{
				font-size: 9px;
				padding-bottom: 2px;
				display: inline-block;
			}
			img{
				max-height: initial;
				margin-bottom: -2px;
				width: 11px;
				@media screen and (max-width: 1199px){
					width: 1.432291666666667rem;
					margin-bottom: -0.260416666666667rem;
				}
				
			}
			border: 1px solid #222;
			
			&.offcanvas-open{
				
				@media screen and (max-width: 767px){
					background: none;
					border: 1px solid rgba(256,256,256,0.5);
				}
			}
			&.hide-quote{
				@media screen and (min-width: 1200px){			
					display: none;
				}
			}
			
		}
		@media screen and (max-width: 1199px){
			flex-direction: row;
			.free-quote{
				flex-direction: row;
				display: inline-block;
				width: auto;
				margin-top: 0;
				margin-left: 2.34375rem;
				padding: 0.520833333333333rem 1.692708333333333rem;
				
				p{
					font-size: 1.953125rem;
					line-height: 1.33em;
					display: inline-block;
				}
				div{
					display: inline-block;
					img{
						margin-bottom: 0;
						margin-right: 1.302083333333333rem;
					}
				}
				
			}
		}
		@media screen and (max-width: 768px){
			.free-quote{
				margin-left: 20px;
				padding: 3px 12px;
				
			}

		}

		@media screen and (max-width: 420px){
			.free-quote{
				padding: 0.7246376812rem 2.898550725rem;
				margin-left: 11.20772947rem;
				img{
					width: 2.657004831rem;
				}
				p{
					font-size: 3.623188406rem;
				}
			}
		}
		
	}
	.navbar-start{
		margin: 24px 0 0 auto;
	}
	.navbar-end{
		margin-top: 24px;
	}
	
	
	.navbar-item{
		text-transform: uppercase;
		padding: 4px 0;
		
		border-bottom: 1px solid rgba(0,0,0,0);
		transition: 0.5s;
		&.is-active{
			transition: 0.5s;
			border-bottom: 1px solid #fff;
		}
		.shopify{
			width: 1.083333333333333rem;
			margin-left: 6px;
		}
	}

	.navbar-start{
		.navbar-item{
			margin-right: 30px;
			&:last-of-type{
				margin-right: 0;
			}
			&.divider{
				pointer-events: none;
				font-size: 1.5em;
				margin-top: -0.25em;
				opacity: 0.5;
			}
		}
	}
	.burger{
		margin-top: 26px;
		position: absolute;
		right: 20px;
		
		img{
			width: 25px;
			height: 22px;
		}
		@media screen and (max-width: 1199px){
			right: 2.604166666666667rem;
			img{
				width: 3.255208333333333rem;
				height: 2.864583333333333rem;
			}
		}


		@media screen and (max-width: 768px){

			margin-top: 28px;
		}

		@media screen and (max-width: 420px){
			margin-top: 26px;
			right: 4.830917874rem;
			img{
				width: 6.280193237rem;
				height: 5.314009662rem;
			}
		}
		
	}
`

const StyledOffcanvas = styled('div')`
	& > div{
		min-height: 100vh;
		background: #212121;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	ul{
		width: 100%;
		li{
			a{
				color: #fff;
				&:hover{
					color: #fff;
					background: none;
				}
				opacity: 0.5;
				border-bottom: 1px solid rgba(112,112,112,0.17);
				padding: 20px;
				font-weight: 200;
				display: flex;
				align-items: center;
				img{
					width: 15px;
					margin-right: 15px;
					opacity: 0.5;
					max-height: initial;
				}
				text-transform: uppercase;
				
				&.is-active{
					background:none;
					opacity: 1;
					img{
						opacity: 1;
					}
					
					color: #fff;
				}
				&:focus{
					background: none;
					color: #fff;
					outline: none;
				}
				@media screen and (max-width: 1199px){
					padding: 2rem 2.604166666666667rem;
					padding-right: 0;
					img{
						width: 1.953125rem;
						margin-right: 1.953125rem;
					}
				}

				@media screen and (max-width: 420px){
					padding: 4.5rem 8.5rem;
					@media screen and (max-height: 736px){
						padding-top: 3.75rem;
						padding-bottom: 3.75rem;
					}
					padding-right: 0;
					img{
						width: 4.347826086956522rem;
						margin-right: 5.314009661835749rem;
					}
				}


			}
			&:last-of-type{
				a{
					border-bottom: none;
				}
			}
		}
		@media screen and (max-width: 420px){
			margin-top: 50px;
		}
	}
`
export default Navbar;
