import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

import phoneLogo from '../assets/free-quote-icon.svg'
import emailIcon from '../assets/email-icon.svg'
import locationIcon from '../assets/footer-location.svg'

import Button from './quoteButton'

import socialInstagram from '../assets/social/instagram.svg'
import socialTwitter from '../assets/social/twitter.svg'
import socialFacebook from '../assets/social/facebook.svg'
import socialGoogle from '../assets/social/google.svg'
import socialLinkedin from '../assets/social/linkedin.svg'

export default ({ type = 'normal', bill = '', yearlyUsage = '', systemSize = '', address = '' }) => {

    const socials = [
        {
            url: 'https://www.instagram.com/kotaenergygroup/',
            icon: socialInstagram,
            alt: 'Instagragm'
        },
        {
            url: 'https://twitter.com/kotaenergygroup/',
            icon: socialTwitter,
            alt: 'Twitter'
        },
        {
            url: 'https://www.facebook.com/KOTAEnergyGroup/',
            icon: socialFacebook,
            alt: 'Facebook'
        },
        {
            url: 'https://www.google.com/search?q=kota+energy+group',
            icon: socialGoogle,
            alt: 'Google'
        },
        {
            url: 'https://www.linkedin.com/company/kota-energy-group/',
            icon: socialLinkedin,
            alt: 'Linkedin'
        }
    ]

    const states = [
        [
            'Arizona',
            'California',
            'Colorado',
            'Florida',
            'Illinois',
            'Massachusettes',
            'New Jersey',
        ],
        [
            'New York',
            'Nevada',
            'North Carolina',
            'South Carolina',
            'Texas',
            'Utah'
        ]
    ]

    const [submitText, setSubmitText] = useState(null);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const encode = (data) => (Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
    )

    // Note we are not using netlify's honeypotting, we are doing it ourselves
    const onSubmit = (event) => {
        event.preventDefault();

        const formElements = [...event.currentTarget.elements];
        const isValid = formElements.filter(elem => elem.name === "bot-field")[0].value === "";

        const validFormElements = isValid ? formElements : [];

        if (validFormElements.length < 1)
            alert("It looks like you filled out too many fields!"); //or some other cheeky error message
        else {
            const body = {
                'form-name': type,
                name,
                phone,
                email
            }

            if (type == 'savings') {
                body.systemSize = systemSize
                body.address = address
                body.monthlyBill = bill
                body.yearlyUsage = yearlyUsage
            }

            fetch("/contact-request", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode(body),
            })
                .then(() => {
                    setSubmitText("Successfully submitted!");
                    setName('')
                    setPhone('')
                    setEmail('')
                })
                .catch(() => {
                    setSubmitText("There was an error with your submission");
                });
        }
    };


    return (

        <StaticQuery
            query={graphql`
                query {
                    nabcep: file(relativePath: { eq: "footer-nabcep-logo@2x.png" }) {
                        childImageSharp {
                            fixed(quality: 90, width: 184, height: 156, cropFocus: CENTER ) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                }
                `}
            render={data => {

                const aosA = {
                    'data-aos': 'fade-in',
                    'data-aos-delay': 500,
                    'data-aos-duration': 1000,
                }
                const aosB = {
                    'data-aos': 'fade-in',
                    'data-aos-delay': 1000,
                    'data-aos-duration': 1000,
                    'data-aos-anchor': '#contact-title'
                }
                return (
                    <StyledFooter id="contact" style={{ minHeight: '100vh' }} className="scroll-child">

                        <div className="container has-text-centered call-out">
                            <div>
                                {type == 'savings' || type == 'careers' ? (
                                    <>
                                        <h1 className="fs-25 s-fs-20 roboto fw-500 has-text-white" {...aosA} id="contact-title">
                                            {type == 'savings' ? 'Get your exact numbers' : 'Get in touch'}
                                        </h1>
                                        <p className="fs-15 s-fs-13 roboto" style={{ color: '#ACACAC' }} {...aosB}>
                                            Promise we will not spam you
                                        </p>
                                        <div className="our-form-wrapper" {...aosB}>
                                            <form
                                                method="POST"
                                                data-netlify="true"
                                                onSubmit={e => onSubmit(e, setSubmitText)}
                                                name={type}
                                                style={{ paddingTop: '20px', paddingBottom: '20px' }}
                                                action='/contact-request'
                                            >
                                                {type == 'savings' && <>
                                                    <input type="hidden" name="systemSize" value={systemSize ? parseFloat(systemSize).toFixed(1) : ''} />
                                                    <input type="hidden" name="monthlyBill" value={bill} />
                                                    <input type="hidden" name="yearlyUsage" value={yearlyUsage ? parseFloat(yearlyUsage).toFixed(0) : ''} />
                                                    <input type="hidden" name="address" value={address} />
                                                </>
                                                }

                                                <input type="hidden" name="form-name" value={type} />

                                                <p style={{ display: "none" }}>
                                                    <label>
                                                        Don’t fill this out if you expect to hear from us!
                                                        <input name="bot-field" value="" readOnly />
                                                    </label>
                                                </p>

                                                <div className="columns is-multiline is-mobile">
                                                    {type == 'savings' && address && address != '' ? (
                                                        <div className="column is-one-third">
                                                            <p className="fw-100 fs-12 m-fs-10 s-fs-10 fake-label has-text-white">Your home address :</p>
                                                            <p className='fs-14 m-fs-11 s-fs-9 has-text-white' style={{ maxWidth: '80%' }}>
                                                                {address}
                                                            </p>
                                                        </div>
                                                    ) : <></>}
                                                    {type == 'savings' && bill ? (
                                                        <div className="column is-one-third field">
                                                            <p className="fw-100 fs-12 m-fs-10 s-fs-10 fake-label has-text-white">Average monthly electricity bill :</p>
                                                            <p className='fs-22 m-fs-17 s-fs-17 has-text-white'>
                                                                ${parseFloat(bill).toFixed(0)}
                                                            </p>
                                                        </div>
                                                    ) : <></>}
                                                    {type == 'savings' && systemSize ? (
                                                        <div className="column is-one-third field">
                                                            <p className="fw-100 fs-12 m-fs-10 s-fs-10 fake-label  has-text-white">Recommended system size :</p>
                                                            <p className='fs-22 m-fs-17 s-fs-17 has-text-white'>
                                                                {parseFloat(systemSize).toFixed(1)} <span className="fs-12 m-fs-9 s-fs-9 fw-200">kW</span>
                                                            </p>
                                                        </div>
                                                    ) : <></>}

                                                    <div className='column is-half field' key='name'>
                                                        <label className="label fw-100 fs-12 m-fs-10 s-fs-12 has-text-white">Your Name</label>
                                                        <div className="control">

                                                            <input
                                                                type='text'
                                                                name='name'
                                                                placeholder='Name'
                                                                className='input fs-14 m-fs-11 s-fs-13'
                                                                value={name}
                                                                onChange={e => setName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='column is-half field' key='name'>
                                                        <label className="label fw-100 fs-12 m-fs-10 s-fs-12 has-text-white">Contact number</label>
                                                        <div className="control">
                                                            <input
                                                                type='text'
                                                                name='phone'
                                                                placeholder='Phone'
                                                                className='input fs-14 m-fs-11 s-fs-13'
                                                                value={phone}
                                                                onChange={e => setPhone(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='column is-full field' key='email'>
                                                        <label className="label fw-100 fs-12 m-fs-10 s-fs-12 has-text-white">Email address</label>
                                                        <div className="control">
                                                            <input
                                                                type='email'
                                                                name='email'
                                                                placeholder='Email'
                                                                className='input fs-14 m-fs-11 s-fs-13'
                                                                value={email}
                                                                onChange={e => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="field column is-full">
                                                        <div className="control" {...aosB}>
                                                            <button className="button is-uppercase roboto fw-500" type="submit">Go</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            {submitText && <p className="fs-15 s-fs-13 roboto has-text-white has-text-centered">{submitText}</p>}
                                        </div>
                                    </>
                                ) :
                                    (
                                        <>
                                            <h1 className="fs-25 s-fs-20 roboto fw-500 has-text-white" {...aosA} id="contact-title"> Savings calculator</h1>
                                            <p className="fs-15 s-fs-13 roboto" style={{ color: '#ACACAC' }} {...aosB}>
                                                Estimate your system size and see how much you’ll save
                                            </p>
                                            <div {...aosB}>
                                                <Button label={'go'} footer={true} />
                                            </div>
                                        </>
                                    )
                                }
                            </div>

                        </div>

                        <div className="container socket" >
                            <div className="columns">
                                <div className="column is-one-third-desktop is-5-tablet is-7-mobile" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                    <div className="contact-line">
                                        <img src={phoneLogo} className="phone" />
                                        <p className="fw-500 gibson fs-22 m-fs-18 s-fs-18">
                                            <a href="tel:+18558005682">855 800 5682</a>
                                        </p>
                                    </div>
                                    <div className="contact-line">
                                        <img src={emailIcon} className="email" />
                                        <p className="fs-12  m-fs-10 s-fs-10 fw-200">
                                            <a href="mailto:info@kotasolar.com">info@kotasolar.com</a>
                                        </p>
                                    </div>
                                    <div className="contact-line">
                                        <img src={locationIcon} className="email" />
                                        <p className="fs-12 m-fs-10 s-fs-10">
                                            <span className="fw-400" style={{ textTransform: 'uppercase' }}>Corporate<br /></span>
                                            <span className="fw-200">
                                                5205 Avenida Encinas Ste D Carlsbad, CA 92008
                                            </span>
                                        </p>

                                    </div>
                                    <ul className="socials nav hide-desktop hide-mobile">
                                        {socials.map(s => (
                                            <li key={'social-icon-' + s.alt}><a href={s.url} >
                                                <img src={s.icon} />
                                            </a></li>
                                        ))}

                                    </ul>
                                    <Img fixed={data.nabcep.childImageSharp.fixed} className="nabcep show-mobile-only" />
                                </div>

                                <div className="column is-one-quarter show-desktop-only" style={{
                                    alignItems: 'flex-end',
                                    justifyContent: 'center'
                                }}>
                                    <ul className="socials nav hide-mobile">
                                        {socials.map(s => (
                                            <li key={'social-icon-' + s.alt}><a href={s.url} >
                                                <img src={s.icon} />
                                            </a></li>
                                        ))}

                                    </ul>
                                </div>
                                <div className="column is-one-third-desktop is-6-tablet is-5-mobile right">
                                    <div className="">
                                        <p className="fs-12 m-fs-12 fw-200 has-text-white state-title">Markets Serviced</p>
                                        <div className="state-container">
                                            <ul>{states[0].map((s, i) => <li key={'market-0-' + i} className="fs-10 m-fs-10 s-fs-10 fw-200">{s}</li>)}</ul>
                                            <ul>{states[1].map((s, i) => <li key={'market-1-' + i} className="fs-10 m-fs-10 s-fs-10 fw-200">{s}</li>)}</ul>
                                        </div>
                                    </div>
                                    <Img fixed={data.nabcep.childImageSharp.fixed} className="nabcep hide-mobile" />
                                </div>
                                <div className="column is-full show-mobile-only">
                                    <ul className="socials nav">
                                        {socials.map(s => (
                                            <li key={'social-icon-' + s.alt}><a href={s.url} >
                                                <img src={s.icon} />
                                            </a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div >

                        </div >
                    </StyledFooter >
                )
            }}
        />
    )
}

const StyledFooter = styled.div`
    background: #000;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    display: flex;

    .show-desktop-only{
        display: none;
        @media screen and (min-width: 1200px){
            display: flex;
        }
    }
    .show-mobile-only{
        display: none !important;
        @media screen and (max-width: 768px){
            display: inline-block !important;
        }
    }
    .hide-mobile{
        @media screen and (max-width: 768px){
            display: none !important;
        }
    }
    .hide-desktop{
        @media screen and (min-width: 1200px){
            display: none !important;
        }
    }
    .our-form-wrapper{
        .kw-alert.kw-alert-error{
            display: none;
        }
        @media screen and (max-width: 1199px) and (min-width: 769px){
            padding-left:   18.75rem;
            padding-right:  18.75rem ;
        }

        
        @media screen and (max-width: 768px){
            padding-left: 8rem;
            padding-right: 8rem;
        }

        @media screen and (max-width: 420px){
            padding-left: 12.8rem;
            padding-right: 12.8rem;
            .column.is-half.field{
                width: 100%;
            }
        }
        p{
            text-align: left;
        }
        .fake-label{
            margin-bottom: 1em;
        }
        
    }
    h1{
        margin-bottom: 7px;
    }
    .call-out{    
        display: flex;
        align-items: center;
        & > div{

        }
        p{
            margin-bottom: 25px;
        }
        @media screen and (max-width: 1024px){
            padding-top: 5em;
        }
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape){
            padding-top: 50px;
        }
        @media screen and (max-width: 420px){
            padding-top: 21.014492753623188rem;
            padding-bottom: 5em;
        }

        
    }

    .contact-line{
        display: flex;
        color: #fff;
        margin-bottom: 20px;
        @media screen and (max-width: 1199px){
            margin-bottom: 3.255208333333333rem;
        }
        @media screen and (max-width: 768px){
                .fw-400{
                    font-weight: 200;
                }
            }
        .phone, .email{
            width: 23px;
            @media screen and (max-width: 420px){
                width: 4.507246376811594rem;
            }

        }
        .phone{
            margin-left: -5px;
            width: 30px;
        }
        .email{
            margin-right: 1em;
            @media screen and (max-width: 420px){
                margin-right: 3em;
            }
        }
        a{
            color: #fff;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    .socials{
        display: flex;
        float: right;
        margin-top: 1em;
        li{
            margin-left: 38px;
            &:first-of-type{
                margin-left: 0;
            }
        }
        &.hide-desktop{
            margin-top: 3.515625rem;
            li{
                @media screen and (max-width: 1199px){
                    margin-left: 4.947916666666667rem;
                    &:first-of-type{
                       margin-left: 0;
                    }
                }
            }
        }
    }
    
    .right{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;
        @media screen and (max-width: 768px){
            align-items: flex-start;
        }
        li{
            color: #acacac;
        }
        .state-title{
            text-align: right;
            text-transform: uppercase;
            margin-bottom: 24px;
            @media screen and (max-width: 768px){
                margin-top: 5px;
            }
        }
        .state-container{
            display: flex;
            flex-wrap: wrap;
            
            ul{
                &:first-of-type{
                    padding-right: 20px;
                }
                li{
                    line-height: 1.8em;
                    text-align: right;
                }
                

                @media screen and (max-width: 768px){
                    width: 100%;
                    &:first-of-type{
                        padding-right: 0;
                    }
                }
            }
            @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: portrait){
                justify-content: flex-end;
                ul{
                    &:first-of-type{
                        padding-right: 0;
                    }
                }
            }
        }
        .nabcep{
            margin-top: 0;
            @media screen and (max-width: 1199px){
                margin-left: 50px;
            }
        }
    }
    .nabcep{
            margin-top: 5.338541666666667rem;
            width: 92px !important;
            height: 78px !important;
            
        }
    
    .socket{
        flex-grow: 0;
        & > .columns{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-bottom: 30px;
            margin-bottom: 0;
            @media screen and (max-width: 1199px){
                padding-bottom: 4.817708333333333rem;
            }
        }
        .is-small{
            display: none;
        }
        width: 100%;
        @media screen and (max-width: 1199px){
            padding: 0 7.291666666666667rem;
            .is-large{
                display: none;
            }
            .is-small{
                display: block;
            } 
            
        }
    }

    .show-mobile-only{
        margin-top: 4.817708333333333rem;
        @media screen and (max-width: 420px){
            margin-top: 12.560386473429952rem;
        }
        .socials{
            width 100%;
            display: flex;
            justify-content: space-between;
            li{
                margin-right: none;

            }
        }
    }

    

`