import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby';
const Button = ({ label = 'Get A Quote', footer = false }) => (
    <div>
        <Link to='/savings-calculator'>
            <StyledButton className={'fs-16 m-fs-12 s-fs-16 fw-500' + (footer ? ' footer' : '')}>
                {label}
            </StyledButton>
        </Link>
    </div>
)

const StyledButton = styled('a')`
    color: #fff;
    background: #D6182B;
    border-radius: 30px;
    padding: 15px 0;
    display: inline-block;
    
    text-transform: uppercase;
    &:hover{
        color: #fff;
    }
    width: 172px;
    text-align: center;
    line-height: 1em;
    @media screen and (max-width: 1199px){
        padding: 1.171875rem 0;
        width: 15.234375rem;
    }

    
    @media screen and (max-width: 420px){
        padding: 2.898550724637681rem 0;
        width: 37.198067632850242rem;
        
    }

    &.footer{
        @media screen and (max-width: 1199px){
            padding: 1.5625rem 0;
            width: 20rem;
        }
        @media screen and (max-width: 480px){
            vertical-align: middle;
            
            padding: 4.1rem 0;
            
            width: 37.198067632850242rem;
        }
    }

`
export default Button